
import settings from "@/settings";
import { defineComponent, ref, reactive, toRef } from "vue";
import { useRegisterFormRules } from "@/model/userFormRules";
import { useCityRelTown } from "@/network/chinaArea";
import { useRegiste } from "@/network/user";

export default defineComponent({
  setup() {



    var test1 = {
      IsUnit: true,
      Summary:
        "\u003Cp\u003E万家建筑群，省保公布的省级重点文物保护单位。\u003C/p\u003E\u003Cp\u003E万家建筑群的公布所在地为，公布类型为古建筑，公布批号为5-263-4-028，公布地址为。\u003C/p\u003E\u003Cp\u003E\u003C/p\u003E",
      City_ID: 178,
      County_ID: 3149,
      City_Name: "淄博市",
      County_Name: "周村区",
      Unit_Property_Names: null,
      InstituteTypeName: "",
      HeritageAgeName: "明",
      HeritageBatch_Name: "第五批",
      HeritageClass_Name: "古建筑",
      HeritageStatus_Name: "",
      HeritageSave_Name: "",
      HeritageTypeName: "建筑群落",
      HeritageLevel_Code: "2",
      HeritageLevel_Name: "省保",
      HeritageNaturalDamageResName: "",
      HeritageHumanDamageResName: "",
      HeritageOwnerNature_Name: "",
      HeritageOwnerIndustry_Name: "当地看管",
      HeritageUsage_Name: "",
      HeritageUserIndustry_Name: "",
      Minority_Name: "",
      NationalTitleName: "",
      TempGuid: "21e94152-8434-4901-8d14-fca52ac8db4a",
      List_ID: 1556,
      AttachType: 41,
      Is_Heritage_Unit: true,
      PartList: [],
      Images: [],
      FundList: [
        {
          Project_Code: "SDWW-2016-02-004",
          Project_Name: "“万家建筑群”——老家庙保护修缮工程",
          ID: 22,
          Allocate_ID: 1,
          Project_ID: 26,
          Allocate_Year: 2016,
          Allocate_Amount: 50,
          Allocate_Name: "SDWW-2016-02-004",
          Comments: "文物保护维修",
          New_Date: "2016-11-29T18:07:53.713",
        },
        {
          Project_Code: "SDWW-2016-02-158",
          Project_Name: "万家村乡村记忆博物馆建设工程",
          ID: 28,
          Allocate_ID: 1,
          Project_ID: 427,
          Allocate_Year: 2016,
          Allocate_Amount: 30,
          Allocate_Name: "SDWW-2016-02-158",
          Comments: "乡村记忆",
          New_Date: "2016-11-29T18:07:53.713",
        },
        {
          Project_Code: "PF-2017-1071",
          Project_Name: "万家建筑群毕氏家祠、中丞府10号院修缮保护工程",
          ID: 2210,
          Allocate_ID: 32,
          Project_ID: 3263,
          Allocate_Year: 2017,
          Allocate_Amount: 30,
          Allocate_Name: "",
          Comments: "",
          New_Date: "2017-12-22T15:11:34.07",
        },
        {
          Project_Code: "PF-2019-1212",
          Project_Name: "万家建筑群毕氏家祠、中丞府10号院修缮工程",
          ID: 2392,
          Allocate_ID: 215,
          Project_ID: 6078,
          Allocate_Year: 2019,
          Allocate_Amount: 46,
          Allocate_Name: "2019年省级文物保护资金",
          Comments: "",
          New_Date: "2019-07-30T10:23:00.95",
        },
      ],
      Total_Fund: 156,
      HasFireHazard: false,
      PeriodicInspectionList: [],
      RoutinePatrolList: [],
      ProjectList: [
        {
          ID: 5356,
          Project_Code: "PA-2019-1149",
          Project_Name: "万家建筑群-玉清茔修缮工程",
          Project_Year: 2019,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 4,
          Budget_Total: 350,
          Budget_Apply_Count: 350,
          Status: 6,
        },
        {
          ID: 3923,
          Project_Code: "PA-2018-1096",
          Project_Name: "“万家建筑群”保护修缮工程",
          Project_Year: 2018,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 4,
          Budget_Total: 660,
          Budget_Apply_Count: 660,
          Status: 7,
        },
        {
          ID: 3967,
          Project_Code: "PA-2018-5018",
          Project_Name: "万家明清建筑群安防工程",
          Project_Year: 2018,
          Project_Classify: 3955,
          Project_Classify_Name: "安全防护工程",
          Project_Type: 4,
          Budget_Total: 210,
          Budget_Apply_Count: 210,
          Status: 7,
        },
        {
          ID: 4488,
          Project_Code: "PP-2018-1107",
          Project_Name: "万家建筑群保护修缮—中丞府6、12号院",
          Project_Year: 2018,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 2,
          Budget_Total: 184.24,
          Budget_Apply_Count: 184.24,
          Status: 5,
        },
        {
          ID: 4490,
          Project_Code: "PP-2018-1112",
          Project_Name: "万家建筑群保护修缮－中丞府1、4、5号院",
          Project_Year: 2018,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 2,
          Budget_Total: 145.36,
          Budget_Apply_Count: 145.36,
          Status: 5,
        },
        {
          ID: 6902,
          Project_Code: "PP-2020-1111",
          Project_Name: "万家建筑群文物保护规划",
          Project_Year: 2020,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 2,
          Budget_Total: 2165.5,
          Budget_Apply_Count: 2165.5,
          Status: 2,
        },
        {
          ID: 26,
          Project_Code: "SDWW-2016-02-004",
          Project_Name: "“万家建筑群”——老家庙保护修缮工程",
          Project_Year: 2016,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 3,
          Budget_Total: 153.2,
          Budget_Apply_Count: 153.2,
          Status: 11,
        },
        {
          ID: 427,
          Project_Code: "SDWW-2016-02-158",
          Project_Name: "万家村乡村记忆博物馆建设工程",
          Project_Year: 2016,
          Project_Classify: 3953,
          Project_Classify_Name: "博物馆",
          Project_Type: 3,
          Budget_Total: 260,
          Budget_Apply_Count: 260,
          Status: 10,
        },
        {
          ID: 3263,
          Project_Code: "PF-2017-1071",
          Project_Name: "万家建筑群毕氏家祠、中丞府10号院修缮保护工程",
          Project_Year: 2017,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 3,
          Budget_Total: 169.03,
          Budget_Apply_Count: 169.03,
          Status: 11,
        },
        {
          ID: 5606,
          Project_Code: "PF-2019-1051",
          Project_Name: "万家建筑群保护修缮工程--1、4、5号院",
          Project_Year: 2019,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 3,
          Budget_Total: 225.59,
          Budget_Apply_Count: 225.59,
          Status: 8,
        },
        {
          ID: 5608,
          Project_Code: "PF-2019-1052",
          Project_Name: "万家建筑群保护修缮工程－6、12号院",
          Project_Year: 2019,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 3,
          Budget_Total: 273.93,
          Budget_Apply_Count: 273.93,
          Status: 9,
        },
        {
          ID: 6076,
          Project_Code: "PF-2019-1210",
          Project_Name: "万家建筑群保护修缮工程--1、4、5号院",
          Project_Year: 2019,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 3,
          Budget_Total: 225.59,
          Budget_Apply_Count: 156.04,
          Status: 9,
        },
        {
          ID: 6078,
          Project_Code: "PF-2019-1212",
          Project_Name: "万家建筑群毕氏家祠、中丞府10号院修缮工程",
          Project_Year: 2019,
          Project_Classify: 3951,
          Project_Classify_Name: "不可移动文物保护",
          Project_Type: 3,
          Budget_Total: 169.03,
          Budget_Apply_Count: 46,
          Status: 10,
        },
      ],
      OtherUnitExt: null,
      Area_Type_Name: "单区域",
      AreaList: [
        {
          Province_Code: "370000",
          City_Code: "370300",
          County_Code: "370306",
          Province_Name: "山东省",
          City_Name: "淄博市",
          County_Name: "周村区",
          ID: 261,
          Unit_ID: 18,
          Province_ID: 22,
          City_ID: 178,
          County_ID: 3149,
          New_User_ID: 0,
          New_Date: "0001-01-01T00:00:00",
          Update_User_ID: 0,
          Update_Date: "0001-01-01T00:00:00",
        },
      ],
      ID: 18,
      Movable: false,
      Area_ID: "370306",
      Area_Name: null,
      Area_Type: 1,
      Heritage_Code: "5-263-4-028",
      HeritageClass_ID: 3556,
      Institute_Type_ID: 0,
      HeritageType_ID: 3583,
      HeritageBatch_ID: 3914,
      HeritageType_Name: null,
      HeritageAge_ID: 3623,
      HeritageAge: "2015年",
      HeritageAge_Name: null,
      Heritage_Name: "万家建筑群",
      Heritage_Address: null,
      Heritage_DetailAddress: null,
      Heritage_ShortName: null,
      Heritage_StateSummary: null,
      Heritage_MergeID: null,
      Heritage_Longitude: null,
      Heritage_LngDegree: 0,
      Heritage_LngMinute: 0,
      Heritage_LngSecond: 0,
      Heritage_Latitude: null,
      Heritage_LatDegree: 0,
      Heritage_LatMinute: 0,
      Heritage_LatSecond: 0,
      Heritage_SeaHeight: null,
      Heritage_PointExplain: null,
      HeritageLevel_ID: 3647,
      Heritage_IsShowFund: false,
      Heritage_StatisticalProvince: null,
      Heritage_BuiltArea: null,
      Heritage_FloorArea: null,
      HeritageSave_ID: 0,
      HeritageStatus_ID: 0,
      Heritage_IsHaveManageOrg: false,
      Heritage_ManageOrg: null,
      Heritage_ManageTel: null,
      Heritage_Owner: null,
      HeritageOwnerNature_ID: 0,
      Heritage_OwnerSuperior: null,
      HeritageOwnerIndustry_ID: 3677,
      HeritageOwnerIndustry: null,
      Heritage_User: null,
      Heritage_UserSuperior: null,
      HeritageUserIndustry_ID: 0,
      HeritageUserIndustry: null,
      Heritage_UserManage: null,
      Heritage_IsNoDamage: false,
      HeritageNaturalDamageRes_ID: 0,
      HeritageNaturalDamageRes_Name: null,
      Heritage_NaturalDamageRes: null,
      HeritageHumanDamageRes_ID: 0,
      HeritageHumanDamageRes_Name: null,
      Heritage_HumanDamageRes: null,
      Heritage_IsMuralsPaintedSculpture: false,
      Heritage_IsGreatDanger: false,
      Heritage_GreatDangerBuildAmount: null,
      Heritage_IsUse: false,
      HeritageUsage_ID: 0,
      HeritageUsage: null,
      Heritage_IsOpen: false,
      Heritage_Tourists: null,
      Heritage_DayTourists: null,
      Heritage_Income: null,
      Heritage_IsOtherTitle: false,
      NationalTitle_ID: 0,
      NationalTitle_Name: null,
      NationalTitle_Other: null,
      Heritage_IsMinority: false,
      Minority_ID: 0,
      Heritage_IsDirectUnit: false,
      Heritage_InformantName: "淄博市",
      Heritage_InformantUnit: null,
      Heritage_InformantTel: "15444444444",
      Heritage_InformantEmail: "SDZBFund@SDFund.com",
      Sequence_ID: 0,
      Inactive: false,
      New_User_Id: 0,
      New_Date: "0001-01-01T00:00:00",
      Update_User_Id: 0,
      Update_Date: "0001-01-01T00:00:00",
      Is_Revolution: false,
      Unit_Property_IDs: "",
      OtherUnit_ID: 0,
      JF_Unit_ID: null,
      HeritageComposeRelation: [],
    };
    var test = {
      isBackByYearFundApply: false,
      isFollowUp: false,
      isCheckData: false,
      newRequestDoc: null,
      requestDoc: null,
      showRequestDoc: true,
      requestDoc1: null,
      requestDoc2: null,
      requestDoc3: null,
      requestDocList: [
        {
          IsNewest: true,
          ID: 1024,
          Project_ID: 6998,
          Project_Code: "PP-2020-1163",
          Project_Name: "烟霞洞遗址文物保护规划编制",
          AttachType: 12,
          Temp_Guid: "00efafff-bf2a-48f4-9870-2d3e54a678e2",
          Request_Type: 1,
          Request_Type_Name: "",
          New_User_Id: 76,
          New_Date: "2020-10-16T15:43:06.667",
          New_User_Name: "烟台市文物局",
          Update_User_Id: 76,
          Update_User_Name: "烟台市文物局",
          Update_Date: "2020-10-16T15:43:06.667",
          AttachId: 26803,
          AttachName:
            "20201009 关于《烟霞洞遗址文物保护规划（2019-2030）》备案的请示.pdf",
          AttachPath:
            "/Angular/attachments/202010/Project/RequestDoc/55665f6718ad4368bfb238664766c18c.pdf",
        },
        {
          IsNewest: true,
          ID: 1027,
          Project_ID: 6998,
          Project_Code: "PP-2020-1163",
          Project_Name: "烟霞洞遗址文物保护规划编制",
          AttachType: 12,
          Temp_Guid: "cadaed1f-f14a-4f93-80ab-8cc3b2de1c2c",
          Request_Type: 2,
          Request_Type_Name: "",
          New_User_Id: 76,
          New_Date: "2020-10-16T15:50:16.083",
          New_User_Name: "烟台市文物局",
          Update_User_Id: 76,
          Update_User_Name: "烟台市文物局",
          Update_Date: "2020-10-16T15:50:16.083",
          AttachId: 26808,
          AttachName:
            "20201009 关于《烟霞洞遗址文物保护规划（2019-2030）》备案的请示.pdf",
          AttachPath:
            "/Angular/attachments/202010/Project/RequestDoc/2c9e212576cd4ae4997f5dd0195539a2.pdf",
        },
      ],
      cityAuditInfo1: null,
      cityAuditInfo2: {
        ActionName: "",
        UserSubType: 51,
        User_Name: "烟台市文物局",
        ShowDetail: false,
        From_Status_Text: null,
        To_Status_Text: null,
        AttachId: 0,
        AttachName: "",
        AttachPath: "",
        ID: 40108,
        Project_ID: 6998,
        Action_Type: 5,
        Action_Result: 1,
        Comments: "",
        From_Status: 3,
        To_Status: 4,
        Ref_Type: 0,
        Ref_Id: 0,
        New_User_Id: 76,
        New_Date: "2020-10-16T15:50:16.047",
      },
      plan_Type_Name: "0",
      status_Text: "办结同意",
      total_Arrange: 0,
      province_Arrange: 0,
      country_Arrange: 0,
      local_Arrange: 0,
      private_Arrange: 0,
      other_Arrange: 0,
      total_Out: 0,
      overal_ID: 6638,
      project_Setup_ID: 0,
      project_Setup_Code: null,
      project_Setup_Name: null,
      project_Setup_Type: 0,
      project_Proposal_ID: 6998,
      project_Proposal_Code: "PP-2020-1163",
      project_Proposal_Name: "烟霞洞遗址文物保护规划编制",
      showApprovalRecord: true,
      fund_Apply_ID: 0,
      fund_Apply_Code: null,
      fund_Apply_Name: null,
      fund_Apply_Unit_ID: 0,
      fund_Apply_Unit_Code: null,
      fund_Apply_Unit_Name: null,
      sub_Classify: 4281,
      sub_Classify_Code: "1",
      sub_Classify_Name: "保护规划及方案编制",
      project_Classify: 3951,
      project_Classify_Code: "1",
      project_Classify_Name: "不可移动文物保护",
      project_Type_Name: "方案",
      project_Property_Code: null,
      project_Property_Name: null,
      project_Unit_Code: "4-067-1-067",
      project_Unit_Name: "烟霞洞遗址",
      unit_Property_Level: "省保",
      unit_City: "",
      unit_County: "",
      saveAsDraft: false,
      copUnitInfo: {
        Grade_Name: "",
        ID: 4608,
        Compile_Unit_Code: "",
        Compile_Unit_Name: "山东省乡土文化遗产保护工程有限公司",
        Qualification_Grade: 0,
        Qualification_Grade_Description: "",
        Legal_Represent: "",
        Address: "",
        Contact_Phone: "",
        Comments: "",
        Status: 1,
        New_User_Id: 188,
        New_Date: "2021-03-22T18:33:48.057",
        Contract_Amount: 0,
        Temp_Project_ID: null,
        Project_Leader: "高宜生",
      },
      financeInfo: {
        ApplyInfo: {
          Attach: {
            HasAttach: false,
            Refer_ID: 0,
            Refer_Type: 23,
            Temp_Guid: "dec5509b-17b2-4b27-ba00-3e3df2e3386d",
            FileExt: ".pdf",
          },
          AttachList: null,
          Project_Type: 2,
          Project_Code: "PP-2020-1163",
          Project_Name: "烟霞洞遗址文物保护规划编制",
          Setup_Doc_ID: 0,
          Setup_Doc_Name: "",
          Proposal_Doc_ID: 0,
          Proposal_Doc_Name: "",
          Reply_Opt_Doc_ID: 0,
          Reply_Opt_Doc_Name: "",
          Status_Text: "",
          ID: 0,
          Project_ID: 6998,
          Apply_Date: "2021-06-22T09:53:33.9950386",
          Assume_Unit: "烟台昆嵛山国家级自然保护区文化和旅游局",
          Start_Date: null,
          End_Date: null,
          Legal_Represent: "",
          Project_Manager: "烟台昆嵛山国家级自然保护区文化和旅游局",
          Finance_Manager: "",
          Comments: "",
          Status: 1,
          New_User_Id: 199,
          New_Date: "2021-06-22T09:53:33.9950386",
          Update_User_Id: 199,
          Update_Date: "2021-06-22T09:53:33.9950386",
        },
        AuditInfo: {
          AcceptAttach: {
            HasAttach: false,
            Refer_ID: 0,
            Refer_Type: 24,
            Temp_Guid: "3f0a35fe-86d7-467b-8a49-8974ac13fd91",
            FileExt: ".pdf",
          },
          AcceptAttachList: null,
          AuditAttach: {
            HasAttach: false,
            Refer_ID: 0,
            Refer_Type: 25,
            Temp_Guid: "3e19a30e-0731-4dbb-a516-be843d1f6351",
            FileExt: ".pdf",
          },
          AuditAttachList: null,
          Project_ID: 6998,
          Project_Code: "",
          Project_Name: "",
          User_Name: null,
          Result_Text: "",
          ID: 0,
          Finance_ID: 0,
          Audit_Result: 0,
          Audit_Date: "2021-06-22T09:53:33.9950386\u002B08:00",
          Audit_Option: "",
          Comments: "",
          Update_Date: "2021-06-22T09:53:33.9950386\u002B08:00",
          New_User_Id: 199,
          New_Date: "2021-06-22T09:53:33.9950386\u002B08:00",
          Update_User_Id: 199,
        },
        NewAudit: {
          AcceptAttach: {
            HasAttach: false,
            Refer_ID: 0,
            Refer_Type: 24,
            Temp_Guid: "3f0a35fe-86d7-467b-8a49-8974ac13fd91",
            FileExt: ".pdf",
          },
          AcceptAttachList: null,
          AuditAttach: {
            HasAttach: false,
            Refer_ID: 0,
            Refer_Type: 25,
            Temp_Guid: "3e19a30e-0731-4dbb-a516-be843d1f6351",
            FileExt: ".pdf",
          },
          AuditAttachList: null,
          Project_ID: 6998,
          Project_Code: "",
          Project_Name: "",
          User_Name: null,
          Result_Text: "",
          ID: 0,
          Finance_ID: 0,
          Audit_Result: 0,
          Audit_Date: "2021-06-22T09:53:33.9950386\u002B08:00",
          Audit_Option: "",
          Comments: "",
          Update_Date: "2021-06-22T09:53:33.9950386\u002B08:00",
          New_User_Id: 199,
          New_Date: "2021-06-22T09:53:33.9950386\u002B08:00",
          Update_User_Id: 199,
        },
        ActionList: [],
      },
      showFinance: false,
      unit_Fund: 0,
      unitFundList: [],
      budgetList: [],
      budget_Total_Fund: 0,
      budget_Central_Fund: 0,
      fundUsePlan: [],
      fundUsePlan_Total: 0,
      fundUsePlan_Central_Total: 0,
      fundUsePlan_Local_Total: 0,
      fundUsePlan_Unit_Total: 0,
      unitComposeList: [],
      actionHistory: [
        {
          ActionName: null,
          UserSubType: 0,
          User_Name: "文保处",
          ShowDetail: false,
          From_Status_Text: "省级已受理",
          To_Status_Text: "办结同意",
          AttachId: 0,
          AttachName: null,
          AttachPath: null,
          ID: 40715,
          Project_ID: 6998,
          Action_Type: 9,
          Action_Result: 1,
          Comments: "办结同意",
          From_Status: 12,
          To_Status: 7,
          Ref_Type: 2,
          Ref_Id: 1586,
          New_User_Id: 188,
          New_Date: "2020-10-23T10:11:33.463",
        },
        {
          ActionName: null,
          UserSubType: 0,
          User_Name: "文保处",
          ShowDetail: false,
          From_Status_Text: "省级待初审",
          To_Status_Text: "省级已受理",
          AttachId: 0,
          AttachName: null,
          AttachPath: null,
          ID: 40714,
          Project_ID: 6998,
          Action_Type: 21,
          Action_Result: 1,
          Comments: "",
          From_Status: 4,
          To_Status: 12,
          Ref_Type: 13,
          Ref_Id: 451,
          New_User_Id: 188,
          New_Date: "2020-10-23T10:10:29.117",
        },
        {
          ActionName: null,
          UserSubType: 0,
          User_Name: "烟台市文物局",
          ShowDetail: false,
          From_Status_Text: "省级待初审",
          To_Status_Text: "省级待初审",
          AttachId: 0,
          AttachName: null,
          AttachPath: null,
          ID: 40129,
          Project_ID: 6998,
          Action_Type: 18,
          Action_Result: 1,
          Comments:
            "\u0022烟台市文物局\u0022转\u0022昆嵛山保护区党群工作部\u0022 ",
          From_Status: 4,
          To_Status: 4,
          Ref_Type: 8,
          Ref_Id: 320,
          New_User_Id: 76,
          New_Date: "2020-10-19T15:03:24.887",
        },
        {
          ActionName: null,
          UserSubType: 0,
          User_Name: "烟台市文物局",
          ShowDetail: false,
          From_Status_Text: "市级待审",
          To_Status_Text: "省级待初审",
          AttachId: 0,
          AttachName: null,
          AttachPath: null,
          ID: 40108,
          Project_ID: 6998,
          Action_Type: 5,
          Action_Result: 1,
          Comments: "",
          From_Status: 3,
          To_Status: 4,
          Ref_Type: 0,
          Ref_Id: 0,
          New_User_Id: 76,
          New_Date: "2020-10-16T15:50:16.047",
        },
        {
          ActionName: null,
          UserSubType: 0,
          User_Name: "烟台市文物局",
          ShowDetail: false,
          From_Status_Text: "草稿",
          To_Status_Text: "市级待审",
          AttachId: 0,
          AttachName: null,
          AttachPath: null,
          ID: 40104,
          Project_ID: 6998,
          Action_Type: 4,
          Action_Result: 1,
          Comments: "",
          From_Status: 1,
          To_Status: 3,
          Ref_Type: 0,
          Ref_Id: 0,
          New_User_Id: 76,
          New_Date: "2020-10-16T15:43:06.723",
        },
        {
          ActionName: null,
          UserSubType: 0,
          User_Name: "烟台市文物局",
          ShowDetail: false,
          From_Status_Text: "草稿",
          To_Status_Text: "草稿",
          AttachId: 0,
          AttachName: null,
          AttachPath: null,
          ID: 40083,
          Project_ID: 6998,
          Action_Type: 1,
          Action_Result: 1,
          Comments: "",
          From_Status: 1,
          To_Status: 1,
          Ref_Type: 0,
          Ref_Id: 0,
          New_User_Id: 76,
          New_Date: "2020-10-10T10:44:38.53",
        },
      ],
      payList: [],
      fundApplyList: [],
      approvedHistory: null,
      attachType: 11,
      fileList: [
        {
          Attach_Item_ID: 5,
          Attach_Name: "市级文物行政部门申请扫描件",
          Attach_Exts: "",
          Allow_Null: false,
          Sequence: 1,
          Comments: "",
          Relation_ID: 12002,
          TempGuid: "252beab6-bc13-4338-8181-1cc8a92dc65e",
          Attach_ID: 26802,
          Original_File_Name:
            "20201009 关于《烟霞洞遗址文物保护规划（2019-2030）》备案的请示.pdf",
          File_Extension: ".pdf",
          File_Size: 1395032,
          Relative_File_Path:
            "/Angular/attachments/202010/Project/Attach/4dee76bd6b2449479887402accf7707d.pdf",
        },
        {
          Attach_Item_ID: 6,
          Attach_Name: "文物保护工程技术方案（PDF格式）",
          Attach_Exts: "",
          Allow_Null: false,
          Sequence: 2,
          Comments: "目前仅限于国保、省保方案，需设计单位公章、项目负责人签字",
          Relation_ID: 12003,
          TempGuid: "fc9d9187-f9cb-4265-97cf-bf5c01e83a6f",
          Attach_ID: 28881,
          Original_File_Name:
            "烟霞洞遗址文物保护规划（2019-2030）（备案稿）.pdf",
          File_Extension: ".pdf",
          File_Size: 155898405,
          Relative_File_Path:
            "/Angular/attachments/202103/Project/Attach/e542e6f9f269438188681e86ac70ff73.pdf",
        },
      ],
      projectProgramPartList: [],
      yearList: null,
      propertyList: null,
      gradeList: null,
      setup_Doc_ID: 0,
      setup_Doc_Name: null,
      needSetupDoc: 0,
      reply_Opt_Doc_ID: 0,
      reply_Opt_Doc_Name: null,
      proposal_Doc_ID: 0,
      proposal_Doc_Name: null,
      needProposalDoc: 0,
      creator_ID: 0,
      project_Category_Name: null,
      mainIssuesAttachType: 14,
      mainIssuesAttachGuid: "994e15d4-d5c7-47ac-8b56-a1509f1c8be4",
      manageInfo: null,
      handleInfo: {
        IsCityHandle: false,
        Project_Type: 2,
        Project_Code: "PP-2020-1163",
        Project_Name: "烟霞洞遗址文物保护规划编制",
        Doc_No: "",
        Status_Text: "",
        Result_Text: "同意",
        Method_Text: "办结",
        AttachType: 81,
        AttachGuid: "fa9309eb-13b0-4d86-bf52-8ff6af1210f9",
        ActID: 1890,
        HasAttach: false,
        AttachList: [],
        HasReview: false,
        ReviewList: null,
        ActionList: [
          {
            NewRequestDoc: null,
            RequestDoc: null,
            Project_ID: 6998,
            Project_Type: 2,
            Project_Code: "PP-2020-1163",
            Project_Name: "烟霞洞遗址文物保护规划编制",
            AuditAttachType: 0,
            AuditGuid: null,
            AuditComments: null,
            User_Name: "文保处",
            Doc_No: "",
            Action_Result_Text: "同意",
            Action_Type_Text: "办结同意",
            Status_Text: "",
            ActType: 0,
            AttachType: 81,
            AttachList: null,
            FileList: null,
            HasAttach: false,
            ID: 1890,
            Handle_ID: 1586,
            Action_Result: 1,
            Action_Type: 32,
            Doc_ID: 0,
            Status: 32,
            Comments: "已备案",
            New_User_Id: 188,
            New_Date: "2020-10-23T10:11:33.463",
          },
        ],
        HasRatify: false,
        RatifyInfo: {
          NewRequestDoc: null,
          RequestDoc: null,
          Project_ID: 0,
          Project_Type: 0,
          Project_Code: null,
          Project_Name: null,
          AuditAttachType: 0,
          AuditGuid: null,
          AuditComments: null,
          User_Name: null,
          Doc_No: null,
          Action_Result_Text: null,
          Action_Type_Text: null,
          Status_Text: null,
          ActType: 0,
          AttachType: 0,
          AttachList: null,
          FileList: null,
          HasAttach: false,
          ID: 0,
          Handle_ID: 0,
          Action_Result: 0,
          Action_Type: 0,
          Doc_ID: 0,
          Status: 0,
          Comments: null,
          New_User_Id: 0,
          New_Date: "0001-01-01T00:00:00",
        },
        RatifyCityAuditInfo: null,
        HasRecord: false,
        RecordInfo: {
          NewRequestDoc: null,
          RequestDoc: null,
          Project_ID: 0,
          Project_Type: 0,
          Project_Code: null,
          Project_Name: null,
          AuditAttachType: 0,
          AuditGuid: null,
          AuditComments: null,
          User_Name: null,
          Doc_No: null,
          Action_Result_Text: null,
          Action_Type_Text: null,
          Status_Text: null,
          ActType: 0,
          AttachType: 0,
          AttachList: null,
          FileList: null,
          HasAttach: false,
          ID: 0,
          Handle_ID: 0,
          Action_Result: 0,
          Action_Type: 0,
          Doc_ID: 0,
          Status: 0,
          Comments: null,
          New_User_Id: 0,
          New_Date: "0001-01-01T00:00:00",
        },
        RecordCityAuditInfo: null,
        HasApproval: false,
        ApprovalInfo: {
          NewRequestDoc: null,
          RequestDoc: null,
          Project_ID: 0,
          Project_Type: 0,
          Project_Code: null,
          Project_Name: null,
          AuditAttachType: 0,
          AuditGuid: null,
          AuditComments: null,
          User_Name: null,
          Doc_No: null,
          Action_Result_Text: null,
          Action_Type_Text: null,
          Status_Text: null,
          ActType: 0,
          AttachType: 0,
          AttachList: null,
          FileList: null,
          HasAttach: false,
          ID: 0,
          Handle_ID: 0,
          Action_Result: 0,
          Action_Type: 0,
          Doc_ID: 0,
          Status: 0,
          Comments: null,
          New_User_Id: 0,
          New_Date: "0001-01-01T00:00:00",
        },
        ApprovalCityAuditInfo: null,
        Apply_Amount: 0,
        ItemList: null,
        Item_Total: 0,
        ID: 1586,
        Project_ID: 6998,
        Handle_Result: 1,
        Handle_Method: 3,
        Doc_ID: 0,
        Comments: "已备案",
        Status: 32,
        New_User_Id: 188,
        New_Date: "2020-10-23T10:11:33.463",
        Update_User_Id: 188,
        Update_Date: "2020-10-23T10:11:33.463",
        Review_Money: 0,
        Accepted_Date: null,
        Accepted_By: null,
      },
      replyOptDocs: [],
      performanceAppraisals: [],
      performanceList: [],
      opinionInfo: null,
      approvalDoc: null,
      projectRecord: null,
      fundList: [],
      phaseProgress: null,
      entrustList: [],
      expertEntrustList: [],
      iD: 6998,
      project_Code: "PP-2020-1163",
      project_Name: "烟霞洞遗址文物保护规划编制",
      project_Unit_ID: 1610,
      project_Type: 2,
      unit_Batch_No: "",
      project_Year: 2020,
      project_Category: 0,
      project_Property: 0,
      project_Owner: "烟台昆嵛山国家级自然保护区文化和旅游局",
      superior_Name: "邹翔宇",
      province_Apply_Count: 0,
      budget_Total: 25,
      budget_Apply_Count: 0,
      assessment_Money: 0,
      project_Manager: "",
      execute_Unit_Name: "",
      manager_Cell_Phone: "0535-4693272",
      manager_Unit_Description: "",
      manager_Contract_Amount: 0,
      unit_Description: "",
      project_Content: "",
      main_Issues: "",
      project_Implement_Plan: "",
      status: 7,
      isApprovalRecord: true,
      new_User_Id: 289,
      new_Date: "2020-10-10T10:44:38.333",
      update_User_Id: 188,
      update_Date: "2021-03-22T18:33:48.057",
      create_Project_Step: 0,
      relicType: 0,
      manage_Status: 0,
      apply_Date: "2020-06-10T00:00:00",
      project_Compile_Unit_Date: "2020-04-10T00:00:00",
      protect_Object: "无",
      project_Approve_Code: "",
      budget_Self_Raised: "          ",
      project_Description: "无",
      project_Compile_Unit_ID: 4608,
      comments: "",
      project_Compile_Unit_Name: "",
      jF_Project_ID: null,
      data_From: null,
      country_Apply_Count: 0,
      other_Apply_Count: 0,
      manage_Use_Unit: "烟台昆嵛山国家级自然保护区文化和旅游局",
      noDoc_Note: "自筹资金",
      implement_Unit: "",
      fund_Manage_Unit: "",
      superior_Contact: "",
      unit_Address: "",
      unit_Brief_Introduce: "",
      brief_Description: "",
      past_Protect_Description: "",
      annual_Execute_Plan: "",
      local_Fund_Usage_Description: "",
      plan_Type: 0,
      to_Country: false,
      projectBudgetDetail: [],
      projectFundUsePlan: [],
    };

    console.log(JSON.stringify(test1));
    const reg = reactive({
      name: null,
      unitcode: null,
      isProvManage: false,
      password: null,
      confirmPassword: null,
      city: null,
      town: null,
      phoneNumber: null,
      safecode: null,
      email: null,
    });

    const [cityOption, townOption] = useCityRelTown(
      toRef(reg, "city"),
      toRef(reg, "town")
    );

    const refForm = ref(null);
    const rules = useRegisterFormRules(refForm);
    const [isSubmiting, registe] = useRegiste(refForm);

    return {
      cityOption,
      townOption,
      isSubmiting,
      refForm,
      reg,
      codedisabled: false,
      sendcode: () => {
        console.log(11);
      },
      MainLogo: require("@/assets/images/Logo.png"),
      style:
        "background: url(" + require("@/assets/images/HeaderBg.png") + ");",
      copyright: settings.copyright,
      codetxt: "发送验证码",
      handleSubmit: registe,
      rules,
    };
  },
});
